import React from "react"
// import {Column} from "../components/layout/bodyColumn"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import Stub from "../components/layout/stub"
// import Column from "../components/layout/bodyColumn"
// import AnimPageContainer from "../components/layout/animPageCont"
import screens from "../images/icons/screens.png"
import ContentBlock from "../components/contentBlock"
import { Link } from "gatsby"
import {H1, Hr} from "../components/layout/Styles"
// import { ExtLinkBtn } from "../components/layout/ui"
import { LinkBtn, RespIframe } from "../components/layout/ui"
const blockData = [
  {
    image: screens,
    // heading: <h3>What is EyeChart.Online ?</h3>,
    text: (
      <p>
        EyeChart.Online is an advanced, modern, and versatile replacement for
        your outdated bulb projector. It is however much more than just simple
        acuity charts. It incorporates a{" "}
        {<Link to="features">whole range of tests</Link>} that would otherwise
        have to be bought separately, saving you thousands. With it’s sleek,
        modern, user friendly and intuitive interface, it’s an absolute pleasure
        to work with and is sure to leave a lasting impression on your patients.
      </p>
    ),
    video: (
      <div style={{overflow: "hidden", paddingTop: "56.25%", position: "relative", marginBottom: "30px"}}>
        <iframe title="video" style={{display: "block", borderRadius: "15px", overflow: "hidden", border: "1px solid black", height: "100%", left: 0, position: "absolute", top: 0, width: "100%"}} src="https://www.youtube.com/embed/_VVY_nPpvXE" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    ),
    fullLengthText: [
      <p>
        EyeChart.Online is completely web based which means there is nothing to
        download, nothing to install and you can use it on any one of your
        devices with a browser and internet connection with no need to transfer
        licences. It truly is as easy as switching on a projector.
      </p>,
      <p>
        EyeChart.Online was developed by an Optometrist who was looking for a
        digital acuity chart and test system which is as simple to use as
        switching on a projector, advanced enough to replace the whole range of
        separate tests and quick enough to speed up the examination procedure
        significantly. The result after more than 2 years of active development
        and refinement is a robust, intuitive, speedy and feature rich
        application which exceeded even our own expectations.
      </p>,
    ],
    buttons: [
      <LinkBtn to="/features">See All Features</LinkBtn>,
      // <ExtLinkBtn
      //   href="https://eyechart.online"
      //   target="_blank"
      //   rel="noopener noreferrer"
      // >
      //   Start Application
      // </ExtLinkBtn>,
    ],
  },
]

const SecondPage = () => (
  <>
    <SEO title="Page two" />
    <Layout>
      {/* <Stub/> */}
      <H1>
        What is EyeChart.Online?
      </H1>
      <Hr style={{ backgroundColor: "salmon" }} />
      {blockData.map((obj, idx) => (
        <ContentBlock key={`p2b-${idx}`} childkey={`p2b-${idx}`} threshold={0.1} {...obj} />
      ))}
    </Layout>
  </>
)

export default SecondPage
